<script setup lang="ts">
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useI18n } from 'vue-i18n';
import { useConsumption } from '../../composables/useConsumption';

import { useFilter } from '../../composables/useFilter';

dayjs.extend(weekOfYear);

// Composables
const { t } = useI18n();
const { getConsumption } = useConsumption();
const { formatNumberToIsoNumber } = useNumbers();
const { getAggregateFilterOption } = useFilter();

// Props
const props = defineProps<{
  dateRange: { start: string; end: string };
}>();

// Data
const aggregationLevel = ref('day');
const resetZoom = ref(false);
const sites = siteStore().getSites.map((site) => site.id);
const meters = areaStore()
  .getRootAreas.filter((area) => {
    return sites.includes(area.site_id);
  })
  .map((area) =>
    area.meters.map((meter) => {
      return {
        id: meter.id,
        name: area.name,
        type: meter.meter_type.energy_type,
      };
    }),
  )
  .flat();

const energyType = ref('all');
const energyTypes = computed(() => {
  const cleanedTypes = meters.map((meter) => meter.type);
  const types = [...new Set(cleanedTypes)].map((type) => {
    return { key: type, label: t(`global.energy_type.${type}`) };
  });
  return [{ key: 'all', label: t('global.all_feminin') }, ...types];
});
const backgroundColors = ['#DB9617', '#F1CA81', '#F7DFB3', '#FCF4E6', '#277D5D', '#4EBB93', '#80CEB2', '#B3E2D1'];

// Load data
const {
  loading,
  data: response,
  error,
  execute,
} = useCustomAsyncData({
  promise: () => loadData(),
  options: {
    immediate: true,
  },
});

// Functions
async function loadData() {
  if (energyTypes.value.length <= 2) {
    energyType.value = energyTypes.value[1].key;
  }
  return Promise.all(
    meters.map(async (meter) => {
      return {
        ...meter,
        consumption: await getConsumption({
          meterId: meter.id,
          ...props.dateRange,
          aggregationLevel: aggregationLevel.value,
        }),
      };
    }),
  );
}

// Computed
const data = computed(() => {
  let dataFiltered = response.value;
  if (!dataFiltered) return [];

  if (energyType.value !== 'all') {
    dataFiltered = dataFiltered.filter((element) => element.type === energyType.value);
  }

  return dataFiltered;
});

const datasets = computed(() => {
  const newDataSets = data.value?.map((element, index) => ({
    data: element.consumption.map((consumption) => consumption.consumption_kwh),
    label: `${element.name} - ` + t(`global.energy_type.${element.type}`),
    backgroundColor: backgroundColors[index % backgroundColors.length],
    minBarLength: 4,
  }));
  return newDataSets ?? [{ data: [], label: '', backgroundColor: '' }];
});
const aggregateOption = computed(() => getAggregateFilterOption());
const timestamps = computed(() => data.value?.[0]?.consumption.map((consumption) => consumption.date) ?? []);

// KPIs totalCo2
const totalConsumption = computed(() => {
  return data.value?.reduce((acc, curr) => {
    return (
      acc +
      curr.consumption.reduce((acc, curr) => {
        return acc + curr.consumption_kwh;
      }, 0)
    );
  }, 0);
});
const totalCost = computed(() => {
  return data.value?.reduce((acc, curr) => {
    return (
      acc +
      curr.consumption.reduce((acc, curr) => {
        return acc + curr.cost;
      }, 0)
    );
  }, 0);
});

const options = computed(() => {
  return {
    responsive: true,
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          stepSize: 1000,
        },
        title: {
          text: t('analytics.consumption_in_kwh'),
          display: true,
          padding: 12,
        },
        grace: '25%',
      },
      x: {
        stacked: true,
        ticks: {
          callback: (value: any) => {
            const date = dayjs(timestamps.value[value]);
            if (aggregationLevel.value === 'week') {
              return dayjs(date).locale('fr').format('YYYY-') + t('global.short_week', { value: dayjs(date).locale('fr').week() });
            } else if (aggregationLevel.value === 'day') {
              return dayjs(date).locale('fr').format('ddd D MMM');
            } else if (aggregationLevel.value === 'month') {
              return dayjs(date).locale('fr').format('MMMM YYYY');
            }
          },
        },
      },
    },
    plugins: {
      tooltip: {
        position: 'nearest',
        callbacks: {
          title: (context: any) => {
            const label = context[0].dataset.label || '';
            return `${label} : ${context[0].label}`;
          },
          label: (context: any) => {
            const index = context.dataIndex;
            const hiddenLabels = context.chart.legend.legendItems
              .filter((legendItem: any) => !legendItem.hidden)
              .map((legendItem: any) => legendItem.text);
            const hiddenValues = context.chart.data.datasets
              .filter((dataset: any) => hiddenLabels.includes(dataset.label))
              .map((dataset: any) => dataset.data[index]);
            const total = hiddenValues.reduce((acc: number, curr: number) => acc + curr, 0);
            const percentage = new Intl.NumberFormat('default', {
              maximumSignificantDigits: 3,
            }).format((context.parsed.y / total) * 100 || 0);

            const value = new Intl.NumberFormat('default', {
              maximumSignificantDigits: 3,
            }).format(context.parsed.y || 0);
            return `${value} kWh (${percentage}%)`;
          },
          footer: (context: any) => {
            return `${t('global.cost')} : ${formatNumberToIsoNumber(
              data.value?.at(context[0].datasetIndex)?.consumption[context[0].dataIndex].cost ?? 0,
            )}€`;
          },
        },
      },
    },
  };
});

// Watchers
watch(
  () => props.dateRange,
  async () => {
    await execute();
    resetZoom.value = true;
  },
  { deep: true },
);
</script>

<template>
  <div class="grid gap-4 grid-cols-2">
    <ui-stat-card :loading="loading" :title="$t('analytics.total_consumption')" icon="Gauge" type="consumption" :data="totalConsumption" />
    <ui-stat-card :loading="loading" :title="$t('global.cost')" type="euro" :data="totalCost" />
  </div>
  <ui-card
    :title="
      $t('global.consumption_of', {
        start: dayjs(dateRange.start).format('ddd DD MMMM YYYY'),
        end: dayjs(dateRange.end).format('ddd DD MMMM YYYY'),
      })
    "
  >
    <template #header>
      <ui-dropdown
        v-model="aggregationLevel"
        :placeholder="$t('global.group_by')"
        :items="aggregateOption"
        @update:model-value="execute()"
      />
      <ui-dropdown v-model="energyType" :disabled="energyTypes.length <= 2" :placeholder="$t('global.energy')" :items="energyTypes" />
    </template>
    <!-- Error -->
    <ui-error v-if="error" :title="$t('error.title')" :message="$t('error.impossible_to_reatrive_consumption_data')" />
    <div v-else class="relative">
      <div v-if="loading" class="absolute z-10 flex items-center justify-center bg-opacity-50 bg-gray-100 top-0 left-0 right-0 bottom-0">
        <ui-loader />
      </div>
      <ui-chart
        type="bar"
        :data="{
          labels: timestamps,
          datasets: datasets,
        }"
        :options="options"
        :plugins="{ 'chartjs-plugin-zoom': true }"
        :reset-zoom="resetZoom"
      />
    </div>
  </ui-card>
</template>
